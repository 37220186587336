<template>
  <div>
    <el-card class="mb15" shadow="hover">
      <el-row>
        <el-input
          :placeholder="$t('search')"
          clearable
          v-model="filter.search"
          @keyup.enter.native="get_list"
        ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
        <el-button @click="get_list" type="primary" class="search">{{
          $t("search_zh")
        }}</el-button>
        <el-button @click="reset" type="info">{{
          $t("btnTip.reset")
        }}</el-button>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <div class="flexBC mb20">
        <el-button type="primary" @click="handleClick('add')">{{
          $t("btnTip.add")
        }}</el-button>
        <el-button type="primary" @click="outExe">{{ $t("outExe") }}</el-button>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{ padding: '6px 0' }"
        :headerCellStyle="{
          background: 'rgba(51, 55, 68, 1)',
          color: '#fff',
        }"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
      <template v-slot:role="slotProps">
          <div>
            <el-tag :type="roleTag(slotProps.callback.row.role)">{{
              role(slotProps.callback.row.role)
            }}</el-tag>
          </div>
        </template>
        <template v-slot:active="slotProps">
          <div>
            <el-tag :type="activeTag(slotProps.callback.row.active)">{{
              active(slotProps.callback.row.active)
            }}</el-tag>
          </div>
        </template>
        <template v-slot:operation="slotProps">
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              slotProps.callback.row.active === 0
                ? $t('account').active1
                : $t('account').active0
            "
            placement="top"
          >
            <el-button
              @click="handleClick('active', slotProps.callback.row)"
              :type="
                slotProps.callback.row.active === 0 ? 'success' : 'warning'
              "
              size="mini"
              >{{
                slotProps.callback.row.active === 0
                  ? $t("account").active1
                  : $t("account").active0
              }}</el-button
            >
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').resetPW"
            placement="top"
          >
            <el-button
              @click="handleClick('edit', slotProps.callback.row)"
              type="info"
              size="mini"
              >{{ $t("btnTip").resetPW }}</el-button
            >
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').edit"
            placement="top"
          >
            <el-button
              @click="handleClick('change', slotProps.callback.row)"
              icon="el-icon-edit-outline"
              :type="'primary'"
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').delete"
            placement="top"
          >
            <el-button
              size="mini"
              type="danger"
              @click="del(slotProps.callback.row.id)"
              >{{ $t("btnTip").delete }}</el-button
            >
          </el-tooltip>
        </template>
      </my-table>
      <page
        :total="list_data.total"
        :page_size.sync="pageSize"
        :page.sync="page"
      />
    </el-card>
    <el-dialog
      :title="$t('account.changePassword')"
      :visible.sync="dialogFormVisible"
      width="500px"
    >
      <form-page
        :ref-obj.sync="formInfo.ref"
        :data="formInfo.data"
        :field-list="formInfo.fieldList"
        :rules="formInfo.rules"
        :label-width="formInfo.labelWidth"
        :list-type-info="listTypeInfo"
        :disabled="formInfo.disabled"
      ></form-page>
      <span slot="footer" class="dialog-footer">
        <el-button @click="done" type="primary" class="ml30">{{
          $t("btnTip.submit")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mixin_list } from "@/mixins";
import page from "@/components/page";
import myTable from "@/components/table";
import FormPage from "@/components/FormPage";
import { accountList, accountEdit, accountDelete } from "@/api/index";
const formInfoData = {
  password: "",
};
export default {
  name: "account",
  mixins: [mixin_list(accountList)],
  components: {
    myTable,
    page,
    FormPage,
  },
  data() {
    return {
      filter: {
        search: "",
      },
      columns: [
        {
          label: "ID",
          sortable: true,
          prop: "id",
          width: 80,
        },
        {
          label: this.$t("account.create_time"),
          sortable: true,
          prop: "create_time",
        },
        {
          label: this.$t("account.username"),
          sortable: true,
          prop: "username",
        },
        {
          label: this.$t("account.role"),
          sortable: true,
          prop: "role",
          slot: "role"
        },
        {
          label: this.$t("account.active"),
          sortable: true,
          slot: "active",
          prop: "active",
        },
        {
          label: this.$t("operation"),
          prop: "",
          width: 300,
          slot: "operation",
        },
      ],
      formInfo: {
        ref: null,
        disabled: false,
        data: formInfoData,
        fieldList: [
          {
            label: this.$t("account").password,
            value: "password",
            type: "input",
            width: "260",
            className: "el-form-block",
            required: true,
            hidden: false,
          },
        ],
        rules: {},
        labelWidth: "120px",
      },
      listTypeInfo: {},
      dialogFormVisible: false,
    };
  },
  methods: {
    active(active) {
      switch (active) {
        case 0:
          return this.$t("account").active0;
        case 1:
          return this.$t("account").active1;
      }
    },
    activeTag(active) {
      switch (active) {
        case 0:
          return "danger";
        case 1:
          return "success";
      }
    },
    role(role) {
      switch (role) {
        case 1:
          return this.$t("account").role1;
        case 2:
          return this.$t("account").role2;
        case 3:
          return this.$t("account").role3;
      }
    },
    roleTag(role) {
      switch (role) {
        case 1:
          return "success";
        case 2:
          return "primary";
        case 3:
          return "warning";
      }
    },
    async handleClick(type, data) {
      switch (type) {
        case "change":
          this.$router.push(`/account/edit?id=${data.id}`);
          break;
        case "add":
          this.$router.push("/account/edit");
          break;
        case "active":
          await accountEdit({ id: data.id, active: data.active === 0 ? 1 : 0 });
          this.get_list();
          break;
        case "edit":
          this.formInfo.data = {
            id: data.id,
            password: "",
          };
          this.formInfo.rules = this.$initRules(this.formInfo.fieldList)
          this.dialogFormVisible = true;
          break;
      }
    },
    done() {
      this.formInfo.ref.validate(async (valid) => {
        if (valid) {
          await accountEdit(this.formInfo.data);
          this.get_list();
        }
      });
      this.dialogFormVisible = false;
    },
    async del(id) {
      this.$confirm(this.$t("sureDelete"), this.$t("tips"), {
        confirmButtonText: this.$t("btnTips").submit,
        cancelButtonText: this.$t("btnTips").cancel,
        type: "warning",
      })
        .then(async () => {
          await accountDelete({ id });
          this.get_list();
        })
        .catch({});
    },
  },
};
</script>